*{
    padding: 0;
    margin: 0;
}
.bg-circle-dsg{
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    opacity: .3;
}

.bg-circle-dsg:nth-child(1){
    translate: -10% -20%;
    background: lightgreen;
}
.bg-circle-dsg:nth-child(2){
    translate: 0 150%;
    width: 30rem; height: 30rem;
    background: lightcoral;
}
