.input-new-user{
    margin: 20px;
}
.blue-circle{
    width: 60rem;
    height: 25rem;
    border-radius: 50%;
    background-color: lightgreen;
    opacity: .5;
    position: absolute;
    translate: 0% -50%;
}